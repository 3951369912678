(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('SidebarController', ['$rootScope', '$scope', '$translate', '$state', '$stateParams', 'Countries', 'Laws', 'LegalFrameworks', 'MapFilters', 'MobileMenu', 'filterFilter', function ($rootScope, $scope, $translate, $state, $stateParams, Countries, Laws, LegalFrameworks, MapFilters, MobileMenu, filterFilter) {
        var changeCountry = function (countryName) {
            Laws.get(function (laws) {
                $scope.laws = laws.data;
                if (countryName) {
                    $scope.filteredLaws = filterFilter($scope.laws, {'Country': countryName}, true);
                    $scope.countryName = countryName;
                    $scope.numCountries = 1;
                    $scope.sidebarArticle = 'country-analysis';
                    $scope.downloadEnabled = true;
                } else {
                    $scope.countryName = null;
                    $scope.numCountries = $scope.countries.length;
                    $scope.sidebarArticle = 'ila-platform-overview';
                    $scope.downloadEnabled = false;
                }
            }, function (error) {
                console.error('Error retrieving laws: ' + error);
            });
            MobileMenu.close();
        };
        $scope.sidebarArticle = 'ila-platform-overview';

        // Filter functions for html mapped from service
        $scope.filterSelected = MapFilters.filterSelected;
        $scope.removeTypeFilter = MapFilters.removeTypeFilter;
        $scope.toggleFrameworkFilter = MapFilters.toggleFrameworkFilter;
        $scope.toggleTimeline = MapFilters.toggleTimeline;
        
        // Filter service watches (update controller scope when service changes)
        $scope.$watch(function () { return MapFilters.timelineOpen; }, function (newValue) {
            $scope.timelineOpen = newValue;
        });
        $scope.$watch(function () { return MapFilters.frameworkFilterOpen; }, function (newValue) {
            $scope.frameworkFilterOpen = newValue;
        });
        $scope.$watch(function () { return MapFilters.frameworkFilterActive; }, function (newValue) {
            $scope.frameworkFilterActive = newValue;
        });
        $scope.$watch(function () { return MapFilters.currentLawCode; }, function (newValue) {
            $scope.currentLawCode = newValue;
        });
        $scope.mobileMenu = MobileMenu;
        
        Countries.get(function (countries) {
            $scope.countries = countries.data;
        }, function (error) {
            console.error('Error retrieving countries: ' + error);
        });
        
        LegalFrameworks.get(function (frameworks) {
            $scope.legalFrameworks = frameworks.data;
        }, function (error) {
            console.error('Error retrieving legal frameworks: ' + error);
        });
        
        if ($state.current.name === 'legalFramework') {
            $scope.activeFramework = $state.params.legalFrameworkSlug;
        }
        if ($state.current.name === 'laws') {
            $scope.lawId = parseInt($state.params.lawId);
        }
        changeCountry($stateParams.countryName);
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name === 'legalFramework') {
                $scope.activeFramework = toParams.legalFrameworkSlug;                
            } else {
                $scope.activeFramework = null;
            }
            if (toState.name === 'laws') {
                $scope.lawId = parseInt(toParams.lawId);
            } else {
                $scope.lawId = null;
            }
            changeCountry(toParams.countryName);
        });
    }]);
})();
