(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('ilaAppPager', function () {
        return {
            templateUrl: 'scripts/components/form/pager.html'
        };
    });
})();
