(function () {
    'use strict';
    
    var app = angular.module('ILAApp');
    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('laws', {
                parent: 'countries.item',
                url: '/laws/:lawId',
                data: {
                    pageTitle: 'ILA - Law'
                },
                params: {
                    lawId: null
                },
                views: {
                    '@': {
                        templateUrl: 'scripts/states/laws/laws.html',
                        controller: 'LawsController'
                    }
                }
            })
    }]);
})();
