(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('MapController', ['$rootScope', '$scope', '$window', '$state', '$stateParams', 'filterFilter', 'Laws', 'Respond', 'MapFilters', function ($rootScope, $scope, $window, $state, $stateParams, filterFilter, Laws, Respond, MapFilters) {
        // default to country map
        $scope.mobile = null;
        $scope.country = $stateParams.countryName;
        $scope.MapFilters = MapFilters;
        var updateMap = function (event, toState, toParams, fromState, fromParams) {
            Laws.get(function (laws) {
                $scope.laws = filterFilter(laws.data, {'Country': toParams.countryName}, true);
            }, function (error) {
                console.error('Error retrieving laws: ' + error);
            });
        
            if (toState.name === 'countries') {
                $scope.currentMap = 'countries';
            } else if (toState.name === 'countries.item') {
                $scope.currentMap = 'laws';
            }
        };
        $rootScope.$on('$stateChangeSuccess', updateMap);
        
        updateMap(null, {name: $state.current.name}, {countryName: $stateParams.countryName});
        angular.element($window).on('resize', function () {
            $scope.$apply();
        });
        $scope.respond = Respond;
    }]);
})();
