(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('LegalFrameworkController', ['$scope', '$stateParams', '$sce', 'filterFilter', 'LegalFrameworkPages', 'MobileMenu', function ($scope, $stateParams, $sce, filterFilter, LegalFrameworkPages, MobileMenu) {
        LegalFrameworkPages.get(function (pages) {
            $scope.legalFramework = filterFilter(pages.data, {'slug': $stateParams.legalFrameworkSlug, 'country': $stateParams.countryName})[0];
            // Grant: ng-bind-html throws an “unsafe” error if HTML isn’t trusted. Trusted aboutPage.html parameters become TrustedValueHolderType objects.
            if ($scope.legalFramework !== undefined) {
                if (typeof $scope.legalFramework.html === 'string') {
                    // Grant: In about.html, .contact-form is displayed under content if includeContactForm = true
                    $scope.legalFramework.html = $sce.trustAsHtml($scope.legalFramework.html);
                }
                MobileMenu.close();
            } else {
                console.log('__ $scope.legalFramework is missing! __');
            }
        }, function (error) {
            console.error('Error retrieving framework pages: ' + error);
        });
    }]);
})();
