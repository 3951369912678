(function () {
    'use strict';

    var app = angular.module('ILAApp', [
        //'ILA-templates',
        'tmh.dynamicLocale',
        'pascalprecht.translate',
        'ngResource',
        'ui.bootstrap',
        'ui.router',
        'angularMoment',
        'ngCookies',
        'ngAnimate',
        'ngSanitize'
    ]);

    app.run(['$rootScope', '$location', '$http', '$window', '$state', '$translate', 'Language', 'amMoment', 'AppConfig', 'Respond', function($rootScope, $location, $http, $window, $state, $translate, Language, amMoment, AppConfig, Respond) {
        $rootScope.ENV = AppConfig.env;
        $rootScope.VERSION = AppConfig.version;
        $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;

            // Update the language
            Language.getCurrent().then(function(language) {
                $translate.use(language);
                amMoment.changeLocale(language);
            });
        });

        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            document.title = toState.data.pageTitle;
        });
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            console.log('State Change Error: ');
            console.dir(error);
        });
        Respond.resize($window.innerWidth, $window.innerHeight);
        angular.element($window).on('resize', function (e) {
            Respond.resize($window.innerWidth, $window.innerHeight);
        });
    }]);

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider', '$translateProvider', 'tmhDynamicLocaleProvider', function($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, $translateProvider, tmhDynamicLocaleProvider) {
        // enable CSRF
        $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

        // $locationProvider.html5Mode(true);
        $urlRouterProvider
            .when('/', '/countries')
            .otherwise('/countries');
        $stateProvider
            .state('root', {
                'abstract': true,
                url: '/',
                views: {
                    '@': {
                        templateUrl: 'scripts/components/map/map.html',
                        controller: 'MapController'
                    },
                    'navbar@': { // declared for whole site, override as needed
                        templateUrl: 'scripts/components/navbar/navbar.html',
                        controller: 'NavbarController'
                    },
                    'sidebar@': {
                        templateUrl: 'scripts/components/sidebar/sidebar.html',
                        controller: 'SidebarController'
                    }
                },
                data: {
                    pageTitle: 'ILA'
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            });

        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useCookieStorage();
        $translateProvider.useSanitizeValueStrategy('escape');
        tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
    }]);
})();
