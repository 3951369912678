(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.service('FarsiNumbers', ['$translate', function ($translate) {
        this.persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        this.convertToFarsi = function (num) {
            var lang = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
            if (lang === 'fa') {
                var strNumber = num.toString();
                for (var i = 0; i < 10; i++) {
                    var regex = new RegExp(i, 'g');
                    strNumber = strNumber.replace(regex, this.persian[i]);
                }
                return strNumber;
            } else {
                return num.toString();
            }
        };
    }]);
    app.directive('toFarsiNum', ['FarsiNumbers', function (FarsiNumbers) {
        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {
                element.text(FarsiNumbers.convertToFarsi(element.text()));
            }
        };
    }]);
})();
