(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.service('DateUtils', function() {
        this.convertLocaleDateToServer = function(date) {
            if (date) {
                var utcDate = new Date();
                utcDate.setUTCDate(date.getDate());
                utcDate.setUTCMonth(date.getMonth());
                utcDate.setUTCFullYear(date.getFullYear());
                return utcDate;
            } else {
                return null;
            }
        };
        this.convertLocaleDateFromServer = function(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        };
        this.convertDateTimeFromServer = function(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        };
    });
    app.filter('dateRange', function() {
        return function(input, startDate, endDate) {
            angular.forEach(input, function(obj) {
                if (obj.received.getTime() >= startDate.getTime() && obj.received.getTime() <= endDate.getTime()) {
                    return obj;
                }
            });
        };
    });
})();
