(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('MapFilters', ['MobileMenu', 'Respond', '$state', '$stateParams', function(MobileMenu, Respond, $state, $stateParams) {
        var MapFilters = {
            currentLawCode: null,
            frameworkFilterActive: false,
            frameworkFilterOpen: true,
            timelineOpen: false,
            removeTypeFilter: function () {
                MapFilters.getLawElements().removeClass('dim');
                MapFilters.currentLawCode = null;
                MapFilters.frameworkFilterActive = false;
                if ($stateParams.lawId) {
                    $state.go('countries.item', {countryName: $stateParams.countryName});
                }
                
                MobileMenu.close();
            },
            filterSelectedNodes: function() {
                if (!MapFilters.currentLawCode) {
                    return;
                }
                var nodes = MapFilters.getLawElements();
                nodes.addClass('dim');
                for (var i = 0; i < nodes.length; i++) {
                    var nodeLawCode = nodes[i].getAttribute('data-law-type');
                    if (MapFilters.currentLawCode === nodeLawCode) {
                        angular.element(nodes[i]).removeClass('dim');
                    }
                }
                $state.go('countries.item', {countryName: $stateParams.countryName});
                MobileMenu.close();
            },
            filterSelected: function (lawCode, $event) {
                MapFilters.currentLawCode = lawCode;
                MapFilters.filterSelectedNodes(lawCode);
                MapFilters.frameworkFilterActive = true;
            },
            toggleFrameworkFilter: function () {
                if (!MapFilters.frameworkFilterOpen) {
                    MapFilters.frameworkFilterOpen = true;
                    if (MapFilters.timelineOpen) {
                        MapFilters.timelineOpen = false;
                    }
                } else {
                    MapFilters.frameworkFilterOpen = false;
                }
            },
            toggleTimeline: function () {
                if (!MapFilters.timelineOpen) {
                    MapFilters.timelineOpen = true;
                    if (MapFilters.frameworkFilterOpen) {
                        MapFilters.frameworkFilterOpen = false;
                    }
                } else {
                    MapFilters.timelineOpen = false;
                }
            },
            getLawElements: function () {
                if (Respond.isWideDesktop()) {
                    return angular.element(document.querySelectorAll('.laws-map .node'));
                } else {
                    return angular.element(document.querySelectorAll('#laws-list-mobile li'));
                }
            }
        };
        return MapFilters;
    }]);
})();
