(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('imageResize', ['$parse', function($parse) {
        return {
            scope: {
                newHeight: '@',
                newWidth: '@'
            },
            link: function($scope, element, attrs) {
                element.one('load', function() {
                    var canvas = document.createElement('canvas');
                    canvas.width = $scope.newWidth;
                    canvas.height = $scope.newHeight;
                    var ctx = canvas.getContext('2d');

                    // calculate aspect ratio
                    var sHeight = element.height();
                    var sWidth = element.width();
                    var hRatio = $scope.newWidth / sWidth;
                    var vRatio = $scope.newHeight / sHeight;

                    // scaling.with Math.min leaves bars, scaling by max gives us a full square
                    var scaling = Math.max(hRatio, vRatio);
                    var hShift = ($scope.newWidth - sWidth * scaling) / 2;
                    var vShift = ($scope.newHeight - sHeight * scaling) / 2;
                    ctx.drawImage(element[0], 0, 0, sWidth, sHeight,
                        hShift, vShift, sWidth * scaling, sHeight * scaling);

                    // style canvas element
                    canvas.style.float = 'right';
                    canvas.style.backgroundColor = '#000000';
                    canvas.style.filter = 'blur(0.5px)';
                    canvas.style.webkitFilter = 'blur(0.5px)';
                    canvas.style.marginLeft = '10px';

                    element.replaceWith(canvas);
                });
            }
        };
    }]);
})();
