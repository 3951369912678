(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('Respond', function() {
        var Respond = {};
        var mobileMaxWidth = 400;
        var tabletMaxWidth = 900;
        var desktopMaxWidth = 1200;
        var current = {
            width: 0,
            height: 0
        };
        var breakpoints = {
            isMobile: function () {
                return current.width < mobileMaxWidth;
            },
            isTablet: function () {
                return current.width <= tabletMaxWidth && current.width > mobileMaxWidth;
            },
            isDesktop: function () {
                return current.width <= desktopMaxWidth && current.width > tabletMaxWidth;
            },
            isWideDesktop: function () {
                return current.width > desktopMaxWidth;
            },
            device: function () {
                if (current.width < mobileMaxWidth) {
                    return 'mobile';
                } else if (current.width <= tabletMaxWidth && current.width > mobileMaxWidth) {
                    return 'tablet';
                } else {
                    return 'desktop';
                }
            }
        };
        Respond.isMobile = breakpoints.isMobile;
        Respond.isTablet = breakpoints.isTablet;
        Respond.isDesktop = breakpoints.isDesktop;
        Respond.isWideDesktop = breakpoints.isWideDesktop;
        Respond.device = breakpoints.device;
        Respond.resize = function (width, height) {
            current.width = width;
            current.height = height;
        };
        return Respond;
    });
})();
