(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('MobileMenu', ['$window', 'Respond', function($window, Respond) {
        var MobileMenu = {};
        MobileMenu.sidebarContainer = document.getElementById("sidebar-container");
        MobileMenu.pageContainer = document.getElementById("page-container");
        
        MobileMenu.open = function () {
            if (Respond.isMobile() || Respond.isTablet()) {
                MobileMenu.sidebarContainer.style.display = "block";
                MobileMenu.pageContainer.style.display = "none";
                document.body.style.backgroundColor = "#001f3c";
                MobileMenu.isOpen = true;
            } else {
                MobileMenu.sidebarContainer.style.display = "block";
                MobileMenu.pageContainer.style.display = "block";
                document.body.style.backgroundColor = "#fff";
                MobileMenu.isOpen = false;
            }
        }
        MobileMenu.close = function () {
            if (Respond.isMobile() || Respond.isTablet()) {
                MobileMenu.sidebarContainer.style.display = "none";
                MobileMenu.pageContainer.style.display = "block";
                document.body.style.backgroundColor = "#fff";
                MobileMenu.isOpen = false;
            } else {
                MobileMenu.sidebarContainer.style.display = "block";
                MobileMenu.pageContainer.style.display = "block";
                document.body.style.backgroundColor = "#fff";
                MobileMenu.isOpen = false;
            }
        }
        
        angular.element($window).on('resize', function () {
            if (!Respond.isMobile() && !Respond.isTablet()) {
                MobileMenu.close();
            }
        });

        MobileMenu.close();
        return MobileMenu;
    }]);
})();
