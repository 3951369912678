(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('NavbarController', ['$scope', '$rootScope', '$state', 'MobileMenu', function ($scope, $rootScope, $state, MobileMenu) {
        $scope.state = {};
        var updateBackLink = function (event, toState, toParams) {
            if (toState.name === 'laws') {
                $scope.state.country = toParams.countryName;
                $scope.state.link = 'laws';
                $scope.state.showLink = true;
            } else if (toState.name === 'countries.item') {
                $scope.state.link = 'countries';
                $scope.state.showLink = true;
            } else {
                $scope.state.showLink = false;
            }
            if ($state.params.pageSlug) {
                $scope.pageSlug = $state.params.pageSlug;
            } else {
                $scope.pageSlug = undefined;
            }
        };
        $scope.mobileMenu = MobileMenu;
        $rootScope.$on('$stateChangeSuccess', updateBackLink);
        updateBackLink(null, $state.$current, {lawId: null});
    }]);
})();
