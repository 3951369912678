(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('countryDownload', ['$rootScope', '$state', '$stateParams', function ($rootScope, $state, $stateParams) {
        return {
            restrict: 'AE',
            scope: {},
            templateUrl: 'scripts/components/countryDownload/countryDownload.html',
            link: function($scope, element, attrs) {
                $scope.countryName = $stateParams.countryName;
                $rootScope.$on('$stateChangeSuccess', function (events, toState, toParams, fromState, fromParams) {
                    $scope.countryName = toParams.countryName;
                });
            }
        };
    }]);
})();
