(function () {
    'use strict';
    
    var app = angular.module('ILAApp');
    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('legalFramework', {
                parent: 'countries.item',
                url: '/frameworks/:legalFrameworkSlug',
                data: {
                    pageTitle: 'ILA - Legal Framework'
                },
                views: {
                    '@': {
                        templateUrl: 'scripts/states/legalFramework/legalFramework.html',
                        controller: 'LegalFrameworkController'
                    }
                }
            })
    }]);
})();
