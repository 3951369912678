(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.filter('characters', function() {
        return function(input, chars, breakOnWord) {
            if (isNaN(chars)) {
                return input;
            }
            if (chars <= 0) {
                return '';
            }
            if (input && input.length > chars) {
                input = input.substring(0, chars);

                if (!breakOnWord) {
                    var lastspace = input.lastIndexOf(' ');
                    // Get last space
                    if (lastspace !== -1) {
                        input = input.substr(0, lastspace);
                    }
                } else {
                    while (input.charAt(input.length - 1) === ' ') {
                        input = input.substr(0, input.length - 1);
                    }
                }
                return input + '...';
            }
            return input;
        };
    });
    app.filter('words', function() {
        return function(input, words) {
            var text;
            if (typeof input === 'string') {
                text = input;
            } else if (typeof input.$$unwrapTrustedValue === 'function') {
                text = input.$$unwrapTrustedValue();
            }
            if (isNaN(words)) {
                return input;
            }
            if (words <= 0) {
                return '';
            }
            if (text) {
                var inputWords = text.split(/\s+/);
                if (inputWords.length > words) {
                    text = inputWords.slice(0, words).join(' ') + '...';
                }
            }
            return text;
        };
    });
})();
