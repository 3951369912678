(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('spinner', ['Spinner', function(Spinner) {
        return {
            restrict: 'AE',
            scope: {},
            templateUrl: 'scripts/components/spinner/spinner.html',
            link: function($scope, element, attrs) {
                $scope.spinning = false;
                $scope.$on('spinstart', function() {
                    $scope.spinning = true;
                });
                $scope.$on('spinstop', function() {
                    $scope.spinning = false;
                });
            }
        };
    }]);
})();
