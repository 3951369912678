(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('Alerts', function() {
        var Alerts;

        Alerts = {
            alerts: [],
            addAlert: function(alert) {
                Alerts.alerts.push(alert);
            },
            getAlerts: function() {
                return Alerts.alerts;
            },
            removeAlert: function(index) {
                Alerts.alerts.splice(index, 1);
            }
        };

        return Alerts;
    });
})();
