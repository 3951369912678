(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('ilaAppPagination', function () {
        return {
            templateUrl: 'scripts/components/form/pagination.html'
        };
    });
})();
