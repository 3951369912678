(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('countries', {
                parent: 'root',
                url: '^/countries',
                data: {
                    pageTitle: 'ILA - Countries'
                },
                views: {
                    '@': {
                        templateUrl: 'scripts/components/map/map.html',
                        controller: 'MapController'
                    },
                    'sidebar@': {
                        templateUrl: 'scripts/components/sidebar/sidebar.html',
                        controller: 'SidebarController'
                    }
                }
            })
            .state('countries.item', {
                parent: 'countries',
                url: '/:countryName',
                data: {
                    pageTitle: 'ILA - Country'
                },
                views: {
                    '@': {
                        templateUrl: 'scripts/components/map/map.html',
                        controller: 'MapController'
                    },
                    'sidebar@': {
                        templateUrl: 'scripts/components/sidebar/sidebar.html',
                        controller: 'SidebarController'
                    }
                }
            });
    }]);
})();
