(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('alerts', ['Alerts', function (Alerts) {
        return {
            restrict: 'AE',
            templateUrl: 'scripts/components/alerts/alerts.html',
            link: function ($scope, element, attrs) {
                $scope.alerts = [];
                $scope.closeAlert = function (index) {
                    Alerts.removeAlert(index);
                };
                $scope.$watch(function () {
                    return Alerts.getAlerts();
                }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        $scope.alerts = newValue;
                    }
                }, true);
            }
        };
    }]);
})();
