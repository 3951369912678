(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('User', ['$resource', 'AppConfig', function ($resource, AppConfig) {
        return $resource(AppConfig.services.ila.base + 'users/:login', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }]);
    app.factory('Countries', ['$resource', '$q', 'AppConfig', 'StaticData', function ($resource, $q, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.countries.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('LawTypes', ['$resource', '$q', 'AppConfig', 'StaticData', function ($resource, $q, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.lawTypes.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('Laws', ['$resource', '$q', 'AppConfig', 'StaticData', function ($resource, $q, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.laws.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('AboutPages', ['$resource', 'AppConfig', 'StaticData', function ($resource, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.aboutPages.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('LegalFrameworks', ['$resource', '$q', 'AppConfig', 'StaticData', function ($resource, $q, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.legalFrameworks.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('LegalFrameworkPages', ['$resource', '$q', 'AppConfig', 'StaticData', function ($resource, $q, AppConfig, StaticData) {
        return {
            'get': function (success, error) {
                return StaticData.legalFrameworkPages.then(function (data, status, headers, config) {
                    success(data, status, headers, config);
                }, function (data, status, headers, config) {
                    error(data, status, headers, config);
                });
            }
        };
    }]);
    app.factory('Feedback', ['$resource', 'AppConfig', '$http', function ($resource, AppConfig, $http) {
        var Feedback = {};
        Feedback.post = function (message, success, error) {
            var url = AppConfig.services.ila.base;
            return $http({
                method: 'POST',
                url: url,
                headers: {
                  'x-api-key': AppConfig.services.ila.key
                },
                data: message
            }).then(function (data, status, headers, config) {
                success(data, status, headers, config);
            },
            function (data, status, headers, config) {
                error(data, status, headers, config);
            });
        };
        return Feedback;
    }]);
})();
