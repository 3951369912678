(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('SidebarAboutController', ['$rootScope', '$scope', '$translate', '$state', 'AboutPages', 'MobileMenu', function ($rootScope, $scope, $translate, $state, AboutPages, MobileMenu) {
        AboutPages.get(function (pages) {
            $scope.aboutPages = pages.data;
        }, function (error) {
            console.error('Error retrieving page content: ' + error);
        });
        $scope.mobileMenu = MobileMenu;
        $scope.activePage = $state.params.pageSlug;
        $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
            $scope.activePage = toParams.pageSlug;
        });
    }]);
})();
