/* globals angular,d3 */
(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('lawsMap', ['$state', '$stateParams', '$rootScope', '$window', '$timeout', '$q', 'Laws', 'MapFilters', 'charactersFilter', 'Respond', 'filterFilter', function($state, $stateParams, $rootScope, $window, $timeout, $q, Laws, MapFilters, charactersFilter, Respond, filterFilter) {
        return {
            restrict: 'AE',
            scope: {},
            templateUrl: 'scripts/components/lawsMap/lawsMap.html',
            link: function($scope, element, attrs) {
                var container = element[0].firstChild;
                var centerPoint = [container.offsetWidth / 2, container.offsetHeight / 2];
                var bubbles = [],
                    current,
                    force,
                    padding = 5,
                    jitter = 0.05,
                    aspect = container.offsetWidth / container.offsetHeight;

                var svg = d3.select('#lawsMap')
                    .append('svg')
                    .attr('width', container.offsetWidth)
                    .attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                    .attr('class', 'bubble-chart');

                var formatData = function (list) {
                    var children = [];
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].Country !== $stateParams.countryName) {
                            continue;
                        }
                        children.push({
                            id: list[i].id,
                            name: list[i]['Law Name'],
                            country: list[i].Country,
                            lawType: list[i]['Law Type'],
                            date: list[i].Date,
                            value: 10 + ((Math.random() * 4) - 2)
                        });
                    }
                    return { children: children };
                };

                var collide = function (jit) {
                    var quadtree = d3.geom.quadtree(bubbles);
                    return function (node) {
                        var r = (node.r),
                            nx1 = node.x - r,
                            nx2 = node.x + r,
                            ny1 = node.y - r,
                            ny2 = node.y + r;
                        return quadtree.visit(function (quad, x1, y1, x2, y2) {
                            if (quad.point && (quad.point !== node)) {
                                var x = node.x - quad.point.x,
                                    y = node.y - quad.point.y,
                                    distance = Math.sqrt(x * x + y * y),
                                    radius = node.r + quad.point.r + padding;
                                if (distance < radius) {
                                    var delta = (distance - radius) / distance * jit;
                                    node.x -= x *= delta;
                                    node.y -= y *= delta;
                                    quad.point.x += x;
                                    quad.point.y += y;
                                }
                            }
                            return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
                        });
                    };
                };

                var gravity = function (alpha) {
                    var cx = centerPoint[0],
                        cy = centerPoint[1],
                        ax = alpha / aspect,
                        ay = alpha / (1 / aspect);

                    return function (d) {
                        d.x += (cx - d.x) * ax;
                        d.y += (cy - d.y) * ay;
                    };
                };

                var tick = function (e) {
                    svg.selectAll('.node')
                        .each(gravity(e.alpha * 0.05))
                        .each(collide(jitter))
                        .attr('transform', function (d) {
                            if (!isNaN(d.x) && !isNaN(d.y)) {
                                return 'translate(' + d.x + ',' + d.y + ')';
                            }
                        });
                };

                var deselect = function () {
                    MapFilters.removeTypeFilter();
                    $scope.$apply();
                    $state.go('countries.item');
                };

                var renderChart = function (laws) {
                    var data = formatData(laws);

                    bubbles = d3.layout.pack()
                        .sort(null)
                        .size([container.offsetWidth, container.offsetHeight])
                        .padding(padding)
                        .nodes(data)
                        .filter(function (d) { return !d.children; });

                    if (Respond.isWideDesktop()) {
                        force = d3.layout.force()
                            .nodes(bubbles)
                            .gravity(0)
                            .charge(0)
                            .size([container.offsetWidth, container.offsetHeight])
                            .on('tick', tick);
                    }

                    // clickable background rect to clear the current selection
                    svg.selectAll('rect').remove();
                    svg.append('rect')
                        .attr('id', 'bubble-background')
                        .attr('width', container.offsetWidth)
                        .attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                        .attr('fill', '#fff')
                        .on('click', deselect);

                    svg.selectAll('.node').remove();
                    var node = svg.selectAll('.node')
                        .data(bubbles)
                        .enter().append('g')
                        .attr('class', 'node law')
                        .attr('data-law-id', function (d) { return d.id; })
                        .attr('data-law-type', function (d) { return d.lawType; })
                        .attr('transform', function (d) {
                            if (!isNaN(d.x) && !isNaN(d.y)) {
                                return 'translate(' + d.x + ',' + d.y + ')';
                            }
                        });

                    MapFilters.filterSelectedNodes(MapFilters.currentLawCode);

                    if (Respond.isWideDesktop()) {
                        node.call(force.drag);
                    }

                    node.append('circle')
                        .attr('r', function (d) {
                            if (!isNaN(d.r)) {
                                return d.r;
                            }
                        });

                    var splitByWord = function (text) {
                        var middle = Math.floor(text.length / 2);
                        var before = text.lastIndexOf(' ', middle);
                        var after = text.indexOf(' ', middle + 1);

                        if (middle - before < after - middle) {
                            middle = before;
                        } else {
                            middle = after;
                        }

                        var s1 = text.substr(0, middle);
                        var s2 = text.substr(middle + 1);
                        return [s1, s2];
                    };

                    var textLength = 0;
                    if (Respond.isMobile()) {
                        textLength = 20;
                    } else if (Respond.isTablet()) {
                        textLength = 30;
                    } else {
                        textLength = 40;
                    }
                    
                    function getSize(d) {
                        var bbox = this.getBBox(),
                            cbbox = this.parentNode.getBBox(),
                            scale = Math.min(cbbox.width/bbox.width, cbbox.height/bbox.height);
                        d.scale = Math.min(scale / 1.15, 15);
                    }

                    node.append('text')
                        .attr('dy', '-20px')
                        .style('text-anchor', 'middle')
                        .attr('class', 'law-name')
                        .text(function (d) {
                            return splitByWord(charactersFilter(d.name, textLength))[0];
                        })
                        .style('font-size', '1px')
                        .each(getSize)
                        .style('font-size', function(d) { return d.scale + 'px'; });
                    node.append('text')
                        .style('text-anchor', 'middle')
                        .attr('class', 'law-name')
                        .text(function (d) {
                            return splitByWord(charactersFilter(d.name, textLength))[1];
                        })
                        .style('font-size', '1px')
                        .each(getSize)
                        .style('font-size', function(d) { return d.scale + 'px'; });
                    node.append('text')
                        .attr('dy', '1.4em')
                        .style('text-anchor', 'middle')
                        .attr('class', 'law-date')
                        .text(function (d) { return d.date; });

                    svg.selectAll('.node').on('click', function (e) {
                        if (d3.event.defaultPrevented) {
                            return;
                        }
                        pickLaw(e.id);
                    });

                    if (Respond.isWideDesktop()) {
                        force.start();
                    }
                };
                
                Laws.get(function (laws) {
                    renderChart(laws.data);
                }, function (error) {
                    console.error('Error retrieving laws: ', error)
                });

                angular.element($window).on('resize', function (e) {
                    if (!container.offsetHeight || !container.offsetWidth) {
                        return;
                    }
                    svg.attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                        .attr('width', container.offsetWidth);
                    aspect = container.offsetHeight / container.offsetWidth;
                    centerPoint = [container.offsetWidth / 2, container.offsetHeight / 2];
                    renderChart();
                });

                var pickLaw = function (lawId) {
                    svg.selectAll('.node').classed('dim', function (d) {
                        // if node is selected
                        if (d.id === lawId) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    $state.go('laws', {lawId: lawId});
                };

                $rootScope.$on('$stateChangeSuccess', function (events, toState, toParams, fromState, fromParams) {
                    if (toState.name.match(/laws/)) {
                        current = toParams.lawId;
                        pickLaw(toParams.lawId);
                    }
                });
            }
        };
    }]);
})();
