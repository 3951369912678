/* globals angular,d3 */
(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('countryMap', ['$state', '$rootScope', '$window', '$timeout', '$location', '$q', 'Countries', 'Respond', function($state, $rootScope, $window, $timeout, $location, $q, Countries, Respond) {
        return {
            restrict: 'AE',
            scope: {},
            templateUrl: 'scripts/components/countryMap/countryMap.html',
            link: function($scope, element, attrs) {
                var container = element[0].firstChild,
                    centerPoint = [container.offsetWidth / 2, container.offsetHeight / 2],
                    bubbles = [],
                    current,
                    force,
                    padding = 5,
                    jitter = 0.4,
                    aspect = container.offsetWidth / container.offsetHeight;

                var svg = d3.select('#countryMap')
                    .append('svg')
                    .attr('width', container.offsetWidth)
                    .attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                    .attr('class', 'bubble-chart');

                var formatData = function (list) {
                    var children = [];
                    for (var i = 0; i < list.length; i++) {
                        children.push({
                            packageName: list[i].name,
                            className: list[i].name,
                            value: list[i].count
                        });
                    }
                    return { children: children };
                };

                var collide = function (jit) {
                    var quadtree = d3.geom.quadtree(bubbles);
                    return function (node) {
                        var r = (node.r),
                            nx1 = node.x - r,
                            nx2 = node.x + r,
                            ny1 = node.y - r,
                            ny2 = node.y + r;
                        return quadtree.visit(function(quad, x1, y1, x2, y2) {
                            if (quad.point && (quad.point !== node)) {
                                var x = node.x - quad.point.x,
                                    y = node.y - quad.point.y,
                                    distance = Math.sqrt(x * x + y * y),
                                    radius = node.r + quad.point.r + padding;
                                if (distance < radius) {
                                    var delta = (distance - radius) / distance * jit;
                                    node.x -= x *= delta;
                                    node.y -= y *= delta;
                                    quad.point.x += x;
                                    quad.point.y += y;
                                }
                            }
                            return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
                        });
                    };
                };

                var gravity = function (alpha) {
                    var cx = centerPoint[0],
                        cy = centerPoint[1],
                        ax = alpha / aspect,
                        ay = alpha / (1 / aspect);

                    return function (d) {
                        d.x += (cx - d.x) * ax;
                        d.y += (cy - d.y) * ay;
                    };
                };

                var tick = function (e) {
                    svg.selectAll('.node')
                        .each(gravity(e.alpha * 0.1))
                        .each(collide(jitter))
                        .attr('transform', function (d) {
                            if (!isNaN(d.x) && !isNaN(d.y)) {
                                return 'translate(' + d.x + ',' + d.y + ')';
                            }
                        });
                };

                var deselect = function () {
                    svg.selectAll('.node').classed('dim', false);
                    $state.go('countries');
                };

                var renderChart = function (countries) {
                    var data = formatData(countries);

                    bubbles = d3.layout.pack()
                        .sort(null)
                        .size([container.offsetWidth, container.offsetHeight])
                        .padding(padding)
                        .nodes(data)
                        .filter(function (d) { return !d.children; });

                    if (Respond.isDesktop() || Respond.isWideDesktop()) {
                        force = d3.layout.force()
                            .nodes(bubbles)
                            .gravity(0.01)
                            .charge(0.01)
                            .size([container.offsetWidth, container.offsetHeight])
                            .on('tick', tick);
                    }

                    // clickable background rect to clear the current selection
                    svg.selectAll('rect').remove();
                    svg.append('rect')
                        .attr('id', 'bubble-background')
                        .attr('width', container.offsetWidth)
                        .attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                        .attr('fill', '#fff')
                        .on('click', deselect);

                    svg.selectAll('.node').remove();
                    var node = svg.selectAll('.node')
                        .data(bubbles)
                        .enter().append('g')
                        .attr('class', 'node country')
                        .attr('data-country', function (d) { return d.className; })
                        .attr('transform', function (d) {
                            if (!isNaN(d.x) && !isNaN(d.y)) {
                                return 'translate(' + d.x + ',' + d.y + ')';
                            }
                        });

                    if (Respond.isDesktop() || Respond.isWideDesktop()) {
                        node.call(force.drag);
                    }

                    node.append('circle')
                        .attr('r', function (d) {
                            if (!isNaN(d.r)) {
                                return d.r;
                            }
                        });
                    node.append('text')
                        //.attr('dy', '0.5em')
                        .style('text-anchor', 'middle')
                        .attr('class', 'country-name')
                        .text(function (d) { return d.className; });
                    node.append('text')
                        .attr('dy', '1.4em')
                        .style('text-anchor', 'middle')
                        .attr('class', 'num-laws')
                        .text(function (d) { return d.value + ' LAWS'; });

                    svg.selectAll('.node').on('click', function (e) {
                        if (d3.event.defaultPrevented) {
                            return;
                        }
                        pickCountry(e.className);
                    });

                    if (Respond.isDesktop() || Respond.isWideDesktop()) {
                        force.start();
                    }
                };
                    

                var pickCountry = function (countryName) {
                    svg.selectAll('.node').classed('dim', function (d) {
                        // if node is selected
                        if (d.className === countryName) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    $state.go('countries.item', {countryName: countryName});
                };
                
                Countries.get(function (countries) {
                    renderChart(countries.data);
                }, function (error) {
                    console.error('Error retrieving countries: ', error)
                });
                
                angular.element($window).on('resize', function (e) {
                    if (!container.offsetHeight || !container.offsetWidth) {
                        return;
                    }
                    svg.attr('height', container.offsetHeight - (container.offsetHeight * 0.01))
                        .attr('width', container.offsetWidth);
                    aspect = container.offsetHeight / container.offsetWidth;
                    centerPoint = [container.offsetWidth / 2, container.offsetHeight / 2];
                    renderChart();
                });
                
                $rootScope.$on('$stateChangeSuccess', function (events, toState, toParams, fromState, fromParams) {
                    if (toState.name.match(/countries/)) {
                        if (toParams.countryName) {
                            current = toParams.countryName;
                            pickCountry(toParams.countryName);
                        } else {
                            deselect();
                        }
                    }
                });
            }
        };
    }]);
})();
