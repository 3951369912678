(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('LawsController', ['$scope', '$stateParams', '$sce', 'Laws', 'Countries', 'filterFilter', 'wordsFilter', function ($scope, $stateParams, $sce, Laws, Countries, filterFilter, wordsFilter) {
        $scope.getLaw = function (lawId) {
            Laws.get(function (laws) {
                var filteredLawArray = filterFilter(laws.data, {id: parseInt(lawId)}, true);
                $scope.law = filteredLawArray[0];
                Countries.get(function (countries) {
                    $scope.country = filterFilter(countries.data, {name: $stateParams.countryName})[0];
                    $scope.country.analysisFiltered = wordsFilter($scope.country.analysis, 100);
                }, function (error) {
                    console.error('Error retrieving countries: ' + error);
                });
            }, function (error) {
                console.error('Error retrieving laws: ' + error);
            });
        };
        $scope.$watch(function () { return $stateParams.lawId; }, function (newValue) {
            $scope.getLaw(newValue);
        });
    }]);
})();
