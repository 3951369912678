(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('StaticData', ['AppConfig', '$http', function (AppConfig, $http) {
        return {
            countries: $http.get('/data/countries.json'),
            laws: $http.get('/data/laws.json'),
            lawTypes: $http.get('/data/lawTypes.json'),
            aboutPages: $http.get('/data/pages.json'),
            legalFrameworks: $http.get('/data/frameworks.json'),
            legalFrameworkPages: $http.get('/data/frameworkPages.json')
        }
    }]);
})();
