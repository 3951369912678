(function () {
    'use strict';
    
    var app = angular.module('ILAApp');
    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('countryAnalysis', {
                parent: 'countries.item',
                url: '/analysis',
                data: {
                    pageTitle: 'ILA - Country Analysis'
                },
                views: {
                    '@': {
                        templateUrl: 'scripts/states/countryAnalysis/countryAnalysis.html',
                        controller: 'CountryAnalysisController'
                    }
                }
            })
    }]);
})();
