(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.factory('Spinner', ['$rootScope', function($rootScope) {
        var Spinner = {};
        Spinner.start = function() {
            $rootScope.$broadcast('spinstart');
        };
        Spinner.stop = function() {
            $rootScope.$broadcast('spinstop');
        };
        return Spinner;
    }]);
})();
