/* ILA (v0.2.0) built for: development @ Wed Mar 02 2016 12:15:35  */
(function () {
    'use strict';
    /* jshint unused: vars */

    var app = angular.module('ILAApp');
    app.constant('AppConfig', {
        env: 'development',
        version: '0.2.0',
        services: {
            ila: {
                key: 'xqPJrdmBvN1Ua6rc7mCmR2FWc2tVXt3M4xIllqbQ',
                base: 'https://api.internetlegislationatlas.org/ila_feedback'
            }
        }
    });
})();
