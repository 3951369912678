(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('rightToLeft', ['$rootScope', 'Language', function($rootScope, Language) {
        return {
            restrict: 'A',
            link: function($scope, element, attrs) {
                // have to check on startup
                Language.getCurrent().then(function(resolved) {
                    // currently only for farsi
                    if (resolved === 'fa') {
                        element.addClass('right-to-left');
                    } else {
                        element.removeClass('right-to-left');
                    }
                });

                $rootScope.$on('$translateChangeSuccess', function($event, update) {
                    // currently only for farsi
                    if (update.language === 'fa') {
                        element.addClass('right-to-left');
                    } else {
                        element.removeClass('right-to-left');
                    }
                });
            }
        };
    }]);
})();
