(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.controller('AboutController', ['$scope', '$stateParams', '$sce', 'AboutPages', 'MobileMenu', 'Feedback', 'Alerts', '$location', '$anchorScroll', function ($scope, $stateParams, $sce, AboutPages, MobileMenu, Feedback, Alerts, $location, $anchorScroll) {
        $scope.submitted = false;
        $scope.contact = {};
        $scope.subjects = [
            'Report issue with content',
            'Report issue with functionality',
            'Share information',
            'Other'
        ];
        AboutPages.get(function (pages) {
            $scope.aboutPage = pages.data[$stateParams.pageSlug];
            if ($scope.aboutPage !== undefined) {
                if (typeof $scope.aboutPage.html === 'string') {
                    // Grant: In about.html, .contact-form is displayed under content if includeContactForm = true
                    // Grant: ng-bind-html throws an “unsafe” error if HTML isn’t trusted. Trusted aboutPage.html parameters become TrustedValueHolderType objects.
                    $scope.aboutPage.html = $sce.trustAsHtml($scope.aboutPage.html);
                }
                if ($stateParams.pageSlug === 'get-involved') {
                    $scope.includeContactForm = true;
                }
                MobileMenu.close();
            } else {
                console.log('__ $scope.aboutPage is missing! __');
            }
        }, function (error) {
            console.error('Error retrieving page content: ' + error);
        });
        $scope.sendFeedback = function () {
            Feedback.post($scope.contact, function () {
                $scope.submitted = true;
                Alerts.addAlert({type: 'success', msg: 'Feedback sent, thank you'});
            }, function (error) {
                Alerts.addAlert({type: 'danger', msg: 'Error sending feedback, please retry'});
            });
        };
    }]);
})();
