(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.when('about', 'about/');
        $stateProvider
            .state('about', {
                parent: 'root',
                url: '^/about/:pageSlug',
                views: {
                    '@': {
                        templateUrl: 'scripts/states/about/about.html',
                        controller: 'AboutController'
                    },
                    'sidebar@': {
                        templateUrl: 'scripts/components/sidebarAbout/sidebarAbout.html',
                        controller: 'SidebarAboutController'
                    }
                },
                data: {
                    pageTitle: 'About ILA'
                },
                params: {
                    pageSlug: 'about-ila'
                }
            });
    }]);
})();
