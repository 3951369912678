(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('countrySelect', ['$rootScope', 'Countries', 'Laws', '$stateParams', 'filterFilter', 'MapFilters', function ($rootScope, Countries, Laws, $stateParams, filterFilter, MapFilters) {
        return {
            restrict: 'AE',
            scope: {},
            templateUrl: 'scripts/components/countrySelect/countrySelect.html',
            link: function ($scope, element, attrs) {
                Laws.get(function (laws) {
                    $scope.laws = laws.data;
                    $scope.country = {
                        current: $stateParams.countryName,
                        currentNumLaws: filterFilter($scope.laws, {'Country': $stateParams.countryName}, true).length
                    };
                    Countries.get(function (countries) {
                        $scope.countries = countries.data;
                        for (var i = 0; i < $scope.countries.length; i++) {
                            var filteredLaws = filterFilter($scope.laws, {'Country': $scope.countries[i].name}, true);
                            $scope.countries[i].numLaws = filteredLaws.length;
                        }
                    }, function (error) {
                        console.error('Error retrieving countries: ' + error);
                    });
                }, function (error) {
                    console.error('Error retrieving laws: ' + error);
                });
                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    // Clear law type filter when current country changes
                    if (toParams.countryName != $scope.country.current) {
                        MapFilters.removeTypeFilter();
                    }

                    $scope.country.current = toParams.countryName;
                    $scope.country.currentNumLaws = filterFilter($scope.laws, {'Country': toParams.countryName}, true).length;
                });
            }
        };
    }]);
})();
