(function () {
    'use strict';

    var app = angular.module('ILAApp');
    app.directive('sidebarArticle', ['$rootScope', '$state', '$stateParams', '$sce', 'AboutPages', 'Countries', 'filterFilter', 'wordsFilter', function ($rootScope, $state, $stateParams, $sce, AboutPages, Countries, filterFilter, wordsFilter) {
        return {
            restrict: 'AE',
            scope: {
                sidebarArticle: '@'
            },
            templateUrl: 'scripts/components/sidebarArticle/sidebarArticle.html',
            link: function ($scope, element, attrs) {
                var updateCountry = function (country) {
                    $scope.country = filterFilter($scope.countries, {name: country})[0];
                    if ($scope.sidebarArticle === 'country-analysis' && $scope.country) {
                        $scope.sidebarArticleTitle = 'Country analysis';
                        $scope.sidebarArticleContent = wordsFilter($scope.country.analysis, 40);
                    } else if ($scope.sidebarArticle === 'ila-platform-overview') {
                        $scope.sidebarArticleTitle = 'Executive summary';
                        $scope.sidebarArticleContent = wordsFilter($scope.pages['executive-summary'].html, 100);
                    }
                };
                
                Countries.get(function (countries) {
                    $scope.countries = countries.data;
                    AboutPages.get(function (pages) {
                        $scope.pages = pages.data;
                        updateCountry($stateParams.countryName);
                    }, function (error) {
                        console.error('Error retrieving page content: ' + error);
                    });
                }, function (error) {
                    console.error('Error retrieving countries: ' + error);
                });
                
                if ($state.current.name === 'laws' || $state.current.name === 'countryAnalysis') {
                    $scope.hideAnalysis = true;
                } else {
                    $scope.hideAnalysis = false;
                }
                $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    if (toState.name === 'laws' || toState.name === 'countryAnalysis') {
                        $scope.hideAnalysis = true;
                    } else {
                        $scope.hideAnalysis = false;
                        updateCountry(toParams.countryName);
                    }
                });
            }
        };
    }]);
})();
